import React from 'react';
// Just working on version react-burger-menu@2.6.1
import {slide as Burger} from 'react-burger-menu';
import HeaderLogo from "../assets/img/Logo_420x100px.png";

export class MobileMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    render() {
        return (
            <div>
                <Burger isOpen={this.state.isOpen} >
                    <a  href="#praxis" className="menu-item">Die Praxis</a>
                    <a  href="#uebermich" className="menu-item">Über mich</a>
                    <a  href="#aktuell" className="menu-item">Aktuell</a>
                    <a  href="#leistungen" className="menu-item">Leistungen</a>
                    <a  href="#kontakt" className="menu-item">Kontakt</a>
                    <a  href="#impressum" className="menu-item">Impressum</a>
                    <a  href="#partner" className="menu-item">Partner</a>
                    <a  href="#datenschutz" className="menu-item">Datenschutz</a>
                </Burger>
                <img src={ HeaderLogo } className="physioLogoMobile" alt="Logo"/>
            </div>
        )
    }
}