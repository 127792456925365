import React from 'react';

export const Partner = (props) => (
    <div className="partner">
        <h2>Partner</h2>
        <div className="partner-wrapper">
            <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.aktivundvital-sportstudio.de">Aktiv & Vital Sportstudio</a></li>
            </ul>
            <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://stb-zambon.de/">Herr Zambon</a></li>
            </ul>
        </div>
    </div>
);