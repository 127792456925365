import React from 'react';
import GoogleMap from "../assets/img/Google_Maps-Standort.jpg";

export const Map = (props) => (
    <div className="map">
        <h2>Hier finden Sie mich</h2>
        <div className="map-wrapper">
            <img src={ GoogleMap } className="mapPic" alt="Map"/>
        </div>
        <p>Gerne können die Parkplätze von Netto genutzt werden, der sich auf der gegenüberliegenden Straßenseite befindet.</p>
    </div>
);