import React from 'react';
import PraxisPic from '../assets/img/Bildergruppe-m-Text.png'

export const Praxis = (props) => (
    <div className="praxis">
        <h2>Die Praxis</h2>
        <div className="image-wrapper">
            <div className="images">
                <img src={ PraxisPic } className="PraxisPic" alt="PraxisPic"/>
            </div>
        </div>
    </div>
);