import React from 'react';
import ICON from "../assets/img/Logo-Mann_507x858px.png";

export const Leistungen = (props) => (
    <div className="leistungen">
        <h2 className="h2">Leistungen</h2>
        <div className="leistungen-wrapper">
            <div>
                <h3>Leistungen nach ärztlicher Verordnung (alle Kassen)</h3>
                <ul>
                    <li> Physiotherapie für Erwachsene</li>
                    <li>Krankengymnastik für Erwachsene</li>
                    <li>Man. Theraphie für Erwachsene und Kinder</li>
                    <li>Klassische Massage</li>
                    <li>Manuelle Lymphdrainage</li>
                    <li>Warmpackung, Heißluft, Heißrolle</li>
                    <li>Traktionsbehandlung / Schlingentischbehandlung</li>
                    <li>Kryotheraphie / Eisbehandlung</li>
                </ul>
            </div>
            <div>
                <h3>Alternativ Methoden und Privatleistungen</h3>
                <ul>
                    <li><a href="https://www.sympathikus-therapie.de/">Symphatikustheraphie nach Dr. Heesch</a></li>
                    <li>Schröpfen, Schröpfmassage</li>
                    <li>Wirbelsäulentheraphie nach Dorn</li>
                    <li>Kinesiotape</li>
                    <li>Die Kassenleistungen werden als<br /> Privatleistung für Selbstzahler,<br /> oder privat verischert Patienten angeboten.</li>
                    <li>Für alle Behandlungen sind Geschenkgutscheine möglich.</li>
                    <li>Preise auf Anfrage.</li>
                    <li>Ich mache keine Hausbesuche.</li>
                </ul>
            </div>
            <img src={ ICON } className="iconPic" alt="Icon"/>
        </div>
        <p>Wichtig: <br/>Termine müssen 24h vorher abgesagt werden.<br/> Ansonsten bin ich berechtigt den Ausfall privat zum Kassensatz in Rechnung zu stellen.</p>
    </div>
);