import React from 'react';

export const Impressum = (props) => (
    <div className="impressum">
        <h2>Impressum</h2>
        <p>Preise und Kontaktdaten sind ab 01.01.2020 gültig.</p>
        <div className="impressum-wrapper">
            <div>
                <ul>
                    <li>Öffnungszeiten:</li>
                    <li>Mo. - Fr.: 09:00-13:00</li>
                    <li>Mo. + Mi. + Do.: 16:00 - 20:00</li>
                    <li>Sa. - So.: geschlossen</li>
                    <li>Termine auch nach Vereinbarung</li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>Nancy Anders . Physio-x-Anders</li>
                    <li>Praxis für Physiotherapie</li>
                    <li>Harburger Str. 46</li>
                    <li>21271 Hanstedt</li>
                    <li>E-Mail: n.anders@physio-x-anders.de</li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>Tel.: 04184 - 89 73 228</li>
                    <li>Fax.: 04184 - 89 73 149</li>
                    <li>St.-Nr.:50/101/11183</li>
                </ul>
            </div>
        </div>
    </div>
);