import React from 'react';
import HeaderLogo from '../assets/img/Logo_420x100px.png'
export const NavBar = (props) => (
    <div className="nav-bar">
        <img src={ HeaderLogo } className="physioLogo" alt="Logo"/>
        <div className="link-wrapper">
            <div className=""><a  href="#praxis">Die Praxis</a></div>
            <div className=""><a  href="#uebermich">Über mich</a></div>
            <div className=""><a  href="#aktuell">Aktuell</a></div>
            <div className=""><a  href="#leistungen">Leistungen</a></div>
            <div className=""><a  href="#kontakt">Kontakt</a></div>
            <div className=""><a  href="#impressum">Impressum</a></div>
            <div className=""><a  href="#partner">Partner</a></div>
            <div className=""><a  href="#datenschutz">Datenschutz</a></div>
        </div>
    </div>
);