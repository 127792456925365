import React from 'react';

export const Kontakt = (props) => (
    <div className="kontakt">
        <h2>Kontakt</h2>
        <div className="kontakt-wrapper">
            <p>Bei Fragen und Terminanfragen stehe ich Ihnen gerne zur Verfügung</p>
            <p>E-Mail: n.anders@physio-x-anders.de</p>
        </div>
    </div>
);