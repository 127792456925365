import React, { Component } from 'react';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom'
import {Praxis} from './sections/Praxis';
import { MobileMenu } from './header/MobileMenu';
import { NavBar} from './header/NavBar';
import ScrollToTop from './util/ScrollToTop';
import {AboutMe} from "./sections/AboutMe";
import {Aktuell} from "./sections/Aktuell";
import {Leistungen} from "./sections/Leistungen";
import {Kontakt} from "./sections/Kontakt";
import {Map} from "./sections/Map";
import {Impressum} from "./sections/Impressum";
import {Partner} from "./sections/Partner";
import {Footer} from "./sections/Footer";
import {Datenschutz} from './datenschutz/Datenschutz';


configureAnchors({
    offset: -100
});


export default class App extends Component {

    home = () => (
        <React.Fragment>
            <div className="page">
                <div className="header">
                    <MobileMenu />
                    <NavBar/>
                </div>
                <div className="background">
                <div className="content-wrapper">
                    <h1>Physio-Praxis in Hanstedt</h1>
                    <ScrollableAnchor id="praxis">
                    <div className="section">
                        <Praxis/>
                    </div>
                    </ScrollableAnchor>
                    <ScrollableAnchor id="uebermich">
                    <div className="section">
                        <AboutMe/>
                    </div>
                    </ScrollableAnchor>
                    <ScrollableAnchor id="aktuell">
                    <div className="section">
                        <Aktuell/>
                    </div>
                    </ScrollableAnchor>
                    <ScrollableAnchor id="leistungen">
                    <div className="section">
                        <Leistungen/>
                    </div>
                    </ScrollableAnchor>
                    <ScrollableAnchor id="kontakt">
                    <div className="section">
                        <Kontakt/>
                    </div>
                    </ScrollableAnchor>
                    <div className="section">
                        <Map/>
                    </div>
                    <ScrollableAnchor id="impressum">
                    <div className="section">
                        <Impressum/>
                    </div>
                    </ScrollableAnchor>
                    <ScrollableAnchor id="partner">
                    <div className="section">
                        <Partner/>
                    </div>
                    </ScrollableAnchor>
                </div>
                </div>
                <ScrollableAnchor id="datenschutz">
                <div className="footer">
                    <Footer/>
                </div>
                </ScrollableAnchor>
            </div>
        </React.Fragment>
    );

    datenschutz = () => (
        <div className="Datenschutz">
            <Datenschutz />
        </div>
    )

    render() {
        return (
            <Router>
                <ScrollToTop>
                    <div>
                        <Route exact path="/" component={this.home} />
                        <Route path="/datenschutz" component={this.datenschutz} />
                    </div>
                </ScrollToTop>
            </Router>

        )
    }
};
