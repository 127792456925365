import React from 'react';
import Man from '../assets/img/Logo-Mann_250x421px.png'

export const AboutMe = (props) => (
    <div className="aboutme">
        <h2>Über mich</h2>
        <div className="aboutme-wrapper">
            <img src={ Man } className="manPic" alt="Logo"/>
            <div className="imagePlaceholder">Sobald das Wetter besser ist, strahlt auch hier ein Bild</div>
            <ul className="lebenslauf">
                <li>1998 Examen Krankenschwester</li>
                <li>1999-2010 Bundeswehr<br/> (Ausbildung zur Rettungssanitäterin)</li>
                <li>Sept.-2011 - Examen Physiotheraphie</li>
                <li>Mai 2012 - Fortbildung MLD</li>
                <li>Juni 2016 - Sept. 2018 - Ausbildung Sympatikus Theraphie</li>
                <li>Man. Theraphie nach osteopatischen Konzept</li>
            </ul>
        </div>
    </div>
);