import React from 'react';

export const Aktuell = (props) => (
    <div className="aktuell">
        <h2>Aktuell</h2>
        <div className="aktuell-wrapper">
            <div>
                <span>Urlaub</span>
                <p>Vom 01.06.2020 bis zum 03.06.2020 bin ich im Urlaub, daher bleibt die Praxis in diesem Zeitraum geschlossen. Ich bitte um Verständnis</p>
            </div>
            <div>
                <span>Krankheit</span>
                <p>Vom XX.XX.XXXX bis zum XX.XX.XXXX bin ich leider Krank, daher bleibt die Praxis in diesem Zeitraum geschlossen. Ich bitte um Verständnis</p>
            </div>
            <div>
                <span>Angebote</span>
                <p>Vom XX.XX.XXXX bis zum XX.XX.XXXX ist die XYZ - Behandlung im Angebot.</p>
            </div>
        </div>
    </div>
);